<div class="navbar-brand">
  <!-- <a
    class="navbar-item"
    href="https://svelte.dev/"
    target="_blank"
    rel="noopener noreferrer">
    <img src="./svelte-icon.png" alt="Svelte" />
  </a> -->
  <router-link to="/" class="navbar-item nav-home">
    <span class="brand-first">Find</span>
    <span class="brand-second">My</span>
    <span class="brand-third">Function</span>
  </router-link>
</div>
