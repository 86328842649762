<script>
  let code = `
function Get-ChildItemRecurse {
  # Get-ChildItem recursively
  param(
    [parameter(Mandatory)]$Path
  )

  Get-ChildItem -Path $Path -Recurse
}

$path = "C:\\temp"
Get-ChildItemRecurse -Path $path 
`;
</script>

<div class="content-container">
  <div class="content-title-group not-found">
    <h2 class="title">Functions</h2>
    <div class="function-block">
      <h3>Get-ChildItemRecurse.ps1</h3>
      <pre>
        <code class="language-powershell">
          {code}
        </code>
      </pre>
    </div>
  </div>
</div>
